@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;900&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

body {
  height: 100vh;
}

.fullscreen {
  height: 100vh;
  background: linear-gradient(149deg, #00c6d7 0%, #167491 80%);
}

body > * {
  font-family: 'Nunito Sans', sans-serif;
}

a {
  color: #3f7de8;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

button, a.button {
  color: #3f7de8;
  background: transparent;
  border-image: url("/button.png") fill 0 30% / 0 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 3em;
  flex-basis: 3em;
  font-size: 22px;
  font-weight: bold;
  line-height: 1em;
  padding: 0 1.5em;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover {
  }

  &:active {
  }

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
}

form.loading {
  button:disabled {
    cursor: wait;
  }
}

.corner-buttons {
  position: fixed;
  bottom: 1em;
  right: 1em;
  cursor: pointer;
  z-index: 1000;

  a {
    padding: 1.5ex;

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.center {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  & > * {
    pointer-events: initial;
  }

  &.game-content {
    margin-top: 65px;
    margin-bottom: 35px;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 998;
  cursor: pointer;
  pointer-events: all;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  margin: 5vmin;
  overflow: hidden;

  color: white;
  background: rgba(255, 255, 255, 0.33);
  outline: 10px solid rgba(255, 255, 255, 0.25);
  border-radius: 0 15ex 0 15ex;
  box-shadow: rgba(255, 255, 255, 0.5) 0 0 2em;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.75);

  & > * {
    margin: -1px;
  }

  &.with-logo:before {
    content: "";
    display: block;
    min-height: 50px;
    width: 100%;
    height: 20%;

    background: url('/blip-con-logo.png') transparent no-repeat center;
    background-size: contain;
  }

  &.small {
    max-width: 90vw;
    max-height: 90vh;
    min-width: 300px;
    min-height: 300px;
    padding: 3ex;
    border-radius: 0 3em 0 3em;

    @media (min-height: 10cm) and (min-width: 10cm) {
      width: 400px;
      height: 400px;
    }
  }

  &.medium {
    width: 90vw;
    height: 70vh;

    @media (min-height: 10cm) and (min-width: 10cm) {
      max-width: 800px;
      max-height: 800px;
    }
  }

  &.large {
    width: 90vw;
    height: 70vh;

    @media (min-height: 10cm) and (min-width: 10cm) {
      max-width: 800px;
      max-height: 800px;
    }
  }

  &.full {
    width: 95vw;
    height: 95vh;
    border-radius: 0 15px 0 15px;
  }

  p {
    text-align: center;
    margin-top: 0.5ex;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;

  a, div {
    border-bottom: 1px solid rgba(255, 255, 255, 0.75);
  }

  div {
    flex-grow: 1;
  }

  a {
    font-size: large;
    padding: 1em 3ex 1.5ex;
    border: 1px solid rgba(255, 255, 255, 0.75);
    border-top: 0;
    background: rgba(63, 125, 232, 0.25);

    &.active {
      border-bottom: 0;
      background: transparent;
    }
  }
}

.content-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background: #00c6d7;
  border-radius: 0 4em 0 4em;
  overflow: hidden;
  margin: 1em 2em 2em;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 1em;
  text-align: center;
  height: 20em;
  width: 16em;

  font-size: 10pt;
  @media (min-height: 500px) {
    font-size: 16pt;
  }

  .image {
    background: rgba(0, 0, 0, 0.2) center;
    background-size: cover;
    min-height: 10em;
    height: 10em;
  }

  .title {
    font-weight: bold;
    padding: 1em 2em 1ex;
  }

  .description {
    padding: 1ex 2em;
    flex-grow: 1;
  }

  button, .button {
    margin: 1ex 1ex 1ex 3em;
  }
}

.debug-note {
  position: absolute;
  text-decoration: none;
  color: #000;
  background: #ffc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 8em;
  width: 8em;
  padding: 1em;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, .7);
  font-size: small;
  bottom: 1em;
  left: 1em;

  strong {
    font-size: large;
  }

  button {
    border-image: none;
    font-size: small;
    margin: 1ex;
    padding: 1ex;
    height: auto;
  }
}

.level-0 {
  filter: invert(75%) sepia(100%) invert(75%) brightness(100%) saturate(0%);
  opacity: 0.5;
}

.level-1 {
  opacity: 1;
  filter: invert(70%) sepia(100%) invert(70%) brightness(100%) saturate(300%) hue-rotate(180deg);
}

.level-2 {
  opacity: 1;
  filter: invert(70%) sepia(100%) invert(70%) brightness(100%) saturate(50%) hue-rotate(180deg);
}

.level-3, .level-max {
  opacity: 1;
  filter: invert(70%) sepia(100%) invert(70%) brightness(100%) saturate(1000%) hue-rotate(200deg);
}
